import { Typography, Box, Container, Divider } from "@mui/material";
import Page from "../component/Page";

export default function Custom404() {
  return (
    <Page>
      <Container
        sx={{
          display: "flex",
          py: 20,
          px: 5
        }}
      >
        <Box minHeight={300}>
          <Typography variant="h1">404</Typography>
          <Box sx={{ pt: 1 }}>
            <Typography variant="h4">Sorry - Page not found</Typography>
            <Typography variant="body">
              The page you are looking for might have been removed or
              temporarily unavailable.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
